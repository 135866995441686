
const ACCOUNT_STATUS = {
	active: 50,
	toInstall: 100,
	toUpgrade: 110,
	upgrading: 150,
	inactive: 0
};

const SERVICES = {
	internal: 0,
	aleda: 1,
	laddition: 2,
	fdj: 3
};

const SERVICE_STATUS = {
	active: 50,
	inactive: 0
};

const SERVICE_CREDENTIAL_STATUS = {
	active: 50,
	inactive: 0
};

const HELPER_STATE = {
	todo: 75,
	done: 100
};

const IMPORT_STATE = {
	failed: 10,
	done: 100
};

const SESSION_STATUS = {
	initialized: 50,
	active: 50,
	expired: 1000,
	toremove: 1002
};

const MESSAGE_SENDING_STATUS = {
	created: 10,
	draft: 50,
	online: 100,
	offline: 150
};

const MESSAGE_READING_STATUS = {
	unseen: 50,
	seen: 100,
	read: 150
};

const MESSAGE_LEVEL = {
	notice: 50,
	warning: 100,
	critical: 150
};

const MESSAGE_PRIORITY_INTERVAL = {
	mostImportant: 0,
	frontPage: 1000,
	noPriority: 99999
};

const USER_STATUS = {
	active: 50,
	inactive: 100
};

export { ACCOUNT_STATUS, SERVICE_STATUS, SERVICE_CREDENTIAL_STATUS,
	HELPER_STATE, IMPORT_STATE, SESSION_STATUS, SERVICES,
	MESSAGE_SENDING_STATUS, MESSAGE_READING_STATUS, MESSAGE_LEVEL, MESSAGE_PRIORITY_INTERVAL,
	USER_STATUS };
